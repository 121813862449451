import { useTranslation } from 'react-i18next';
import about from '../assets/img/about.jpeg'

export const About = () => {
    const { t } = useTranslation();

    return (<div className="entry-content clearfix">
        <div className="wp-block-media-text alignwide is-stacked-on-mobile" style={{ gridTemplateColumns: '25% auto' }}>
            <figure className="wp-block-media-text__media">
                <img src={about} alt="me" decoding="async" width="683" height="1024" />
            </figure>
            <div className="wp-block-media-text__content">
                <p className="has-medium-font-size">{t('about1')}</p>
            </div>
        </div>
        <br />
        <p className="has-medium-font-size">• {t('about2')}</p>
        <p className="has-medium-font-size">• {t('about3')}</p>
        <p className="has-medium-font-size">• {t('about4')}</p>
        <p className="has-medium-font-size">• {t('about5')}</p>
        <p className="has-medium-font-size">• {t('about6')}</p>
        <p className="has-medium-font-size">• {t('about7')}</p>
        <p className="has-medium-font-size">• {t('about8')}</p>
    </div>)
}